<template>
  <div class="searchWords">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Index'
}
</script>

<style scoped lang='less'>
  @import '../../styles/common/variables.less';
  @import '../../styles/common/mixins.less';
  .overview{
    padding: 24px 24px 0 24px;
    background: @f567;
  }
</style>
